<template>
  <section class="search--profile" v-if="owner">
    <div class="d-flex justify-content-center">
      <div class="d-flex align-items-center">
        <img
          class="profile--picture"
          :src="!owner ? '/img/placeholder.png' : owner.photo_profile_user"
        />
        <div style="flex-grow: 1;">
          <div class="name">
            {{ owner.fullname }}
          </div>
          <div class="job">
            {{ owner.job_title }}
          </div>
          <div class="company">
            {{ owner.company_name }}
          </div>
          <div class="agent--reg-no" v-if="owner.agent_reg_no">
            {{ $t('general.agentRegNo') }} {{ owner.agent_reg_no }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-center flex-wrap"
      v-if="owner.social_medias && owner.social_medias.length > 0"
      @click="stopPropagation"
    >
      <div v-for="(socmed, index) in owner.social_medias" :key="`social-media-${index}`">
        <a :href="socmed.url" target="_blank" class="social--media">
          <img
            src="@/assets/img/icons/instagram-2.svg"
            v-if="socmed.name === 'instagram'"
            alt="Instagram"
          />
          <img
            src="@/assets/img/icons/facebook-2.svg"
            v-else-if="socmed.name === 'facebook'"
            alt="Facebook"
          />
          <img
            src="@/assets/img/icons/tiktok-2.svg"
            v-else-if="socmed.name === 'tiktok'"
            alt="Tiktok"
          />
          <div>{{ socmed.name[0].toUpperCase() + socmed.name.substring(1) }}</div>
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-center align-center flex-wrap">
      <a :href="`tel:${owner.phone}`" class="btn btn-primary my-2 mx-2">
        <i class="ion-android-call mr-2"></i>
        {{ $t('contact.modal.call') }}
      </a>
      <a :href="`https://wa.me/${owner.phone}`" target="_blank" class="btn btn--whatsapp my-2 mx-2">
        <img class="mr-2" src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
        WhatsApp
      </a>
    </div>
    <div class="about">
      {{ owner.about_me }}
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'search-profile',
  computed: {
    ...mapState({
      owner: state => state.v2.profile.owner,
    }),
  },
  methods: {
    stopPropagation(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.social--media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-top: 4px;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 4px;
  }
}
</style>
